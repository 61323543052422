import { faCircle, faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { DrawerActions } from '@react-navigation/native';
import { Avatar, Button, ListItem } from '@rneui/themed';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FlatList,
  Image,
  Linking,
  ListRenderItemInfo,
  RefreshControl,
  Share,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { sendVoteLiveFeedSurvey } from '../api/radioWebsiteApi';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { COLORS, DataState } from '../constants';
import {
  selectAppConfig,
  selectDivRadius,
  selectFontFamily,
  selectHasMargin,
  selectMainColor,
  selectTextAlign,
} from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import {
  computeSurveyResultBarStyles,
  parseSurveyResults,
} from '../features/liveFeed/helpers/survey';
import { fetchLiveFeed as fetchLiveFeedAction } from '../features/liveFeed/store/actions';
import {
  selectLiveFeed,
  selectLiveFeedState,
} from '../features/liveFeed/store/selectors';
import { showAlert } from '../features/modal/store/actions';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import { useShadowStyles } from '../hooks/useShadowStyles';
import { dateFromSQLUTC, formatFromNow } from '../localization/datetime';
import i18n from '../localization/i18n';
import {
  LiveFeedPostArticleModel,
  LiveFeedPostModel,
  LiveFeedPostSurveyAnswer,
  LiveFeedPostSurveyModel,
  LiveFeedPostSurveyResultsModel,
  LiveFeedTabModel,
  TextAlign,
} from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import {
  IS_ANDROID,
  IS_WEB,
  isTablet,
  REDUCED_HORIZONTAL_MARGIN,
} from '../sdk/constants';
import { getFontFamily } from '../sdk/helpers/fontMapper';
import { getSecondaryColor } from '../sdk/helpers/getSecondaryColor';
import {
  thumbnailUrlFromYoutubeUrl,
  thumbnailUrlFromYoutubeVideoId,
} from '../sdk/helpers/socialVideo';

export default function LiveFeedScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'LiveFeed'>) {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const liveFeed = useAppSelector(selectLiveFeed);
  const liveFeedState = useAppSelector(selectLiveFeedState);
  const liveFeedListRef = useRef<FlatList<LiveFeedPostModel>>(null);
  const [refreshing, setRefreshing] = useState(false);
  const tabOptions = route.params.options as LiveFeedTabModel;
  const bearerToken = useAppSelector(selectBearerToken);
  const mainColor = useAppSelector(selectMainColor);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const bodyHorizontalPadding = hasMargin ? mvs(32) : REDUCED_HORIZONTAL_MARGIN;
  const maxWidth = useMemo(() => {
    const withMarginMaxWidth = IS_WEB ? 300 : mvs(450);
    return hasMargin ? withMarginMaxWidth : '100%';
  }, [hasMargin]);

  // dynamic font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const liveFeedPostMessageFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const liveFeedPostTitleFontSize = useAdjustedFontSize(mvs(18, 0.35));

  const fetchLiveFeed = useCallback(() => {
    dispatch(fetchLiveFeedAction(appConfig, bearerToken));
  }, [appConfig, bearerToken, dispatch]);

  useEffect(() => {
    fetchLiveFeed();
  }, [fetchLiveFeed]);

  useEffect(() => {
    if (liveFeedState !== DataState.LOADING) {
      setRefreshing(false);
    }

    if (liveFeedState === DataState.ERROR) {
      dispatch(
        showAlert({
          key: 'fetch-livefeed-error',
          type: 'warning',
          text: i18n.t('alert.noConnection'),
          closable: false,
          autoCloseAfterMs: 2000,
        }),
      );
    }
  }, [dispatch, liveFeedState]);

  const scrollToTop = useCallback(
    () => liveFeedListRef.current?.scrollToOffset({ offset: 0 }),
    [],
  );

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetchLiveFeed();
  }, [fetchLiveFeed]);

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const menuIcon = useMemo(() => {
    return (
      <CustomIcon
        name="icon_menu"
        color={tabOptions.headerTextColor}
        size={mvs(24)}
        onPress={toggleLeftDrawer}
      />
    );
  }, [toggleLeftDrawer, tabOptions]);

  const tabName = useMemo(() => {
    return (
      <View style={styles.titleContainer}>
        <TouchableOpacity onPress={scrollToTop}>
          <CustomText
            numberOfLines={1}
            style={[
              styles.title,
              { color: tabOptions.headerTextColor, fontSize: titleFontSize },
            ]}
            weight="Bold"
          >
            {tabOptions.titre}
          </CustomText>
        </TouchableOpacity>
      </View>
    );
  }, [scrollToTop, tabOptions, titleFontSize]);

  const renderLiveFeedPost = useCallback(
    ({ item: liveFeedPost }: ListRenderItemInfo<LiveFeedPostModel>) => {
      const type = liveFeedPost.type;
      const message = liveFeedPost.message?.trim() ?? '';
      const picture = liveFeedPost.photo;
      const youtubeUrl = liveFeedPost.urlyoutube;
      const youtubeId = liveFeedPost.idyoutube;
      const link = liveFeedPost.lien;
      let contents = (
        <>
          {picture ? (
            <Image
              style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
              source={{ uri: picture }}
            />
          ) : null}
          <CustomText
            style={[
              styles.liveFeedPostMessage,
              { fontSize: liveFeedPostMessageFontSize },
            ]}
            textAlign={textAlign}
            weight="Regular"
          >
            {message}
          </CustomText>
        </>
      );

      if (youtubeUrl) {
        const thumbnailUrl =
          picture ??
          (youtubeId
            ? thumbnailUrlFromYoutubeVideoId(youtubeId)
            : thumbnailUrlFromYoutubeUrl(youtubeUrl));
        contents = (
          <>
            <TouchableOpacity
              style={IS_WEB ? { pointerEvents: 'none' } : ''}
              onPress={() => Linking.openURL(youtubeUrl)}
            >
              {thumbnailUrl ? (
                <View style={styles.liveFeedPostPictureContainer}>
                  <Image
                    style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
                    source={{ uri: thumbnailUrl }}
                  />
                  <View
                    style={[
                      styles.liveFeedPostVideoButton,
                      { backgroundColor: getSecondaryColor(mainColor) },
                      IS_WEB ? { pointerEvents: 'none' } : undefined,
                    ]}
                  >
                    <CustomIcon
                      name="icon_play_video_with_offset"
                      color={mainColor}
                      size={mvs(32)}
                    />
                  </View>
                </View>
              ) : (
                <CustomText
                  style={[
                    styles.liveFeedPostMessage,
                    styles.liveFeedPostTextLink,
                    { fontSize: liveFeedPostMessageFontSize },
                  ]}
                  weight="Regular"
                  textAlign={textAlign}
                >
                  {youtubeUrl}
                </CustomText>
              )}
            </TouchableOpacity>
            <CustomText
              style={[
                styles.liveFeedPostMessage,
                { fontSize: liveFeedPostMessageFontSize },
              ]}
              textAlign={textAlign}
              weight="Regular"
            >
              {message}
            </CustomText>
          </>
        );
      } else if (
        type === 'agenda' ||
        type === 'galeries' ||
        type === 'news' ||
        type === 'podcasts' ||
        type === 'videos'
      ) {
        const article = liveFeedPost as LiveFeedPostArticleModel;
        const title = article.titre?.trim() ?? '';
        const description = article.description?.trim() ?? '';
        contents = (
          <>
            {picture ? (
              <View style={styles.liveFeedPostPictureContainer}>
                <Image
                  style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
                  source={{ uri: picture }}
                />
                {type === 'videos' && link ? (
                  <View
                    style={[
                      styles.liveFeedPostVideoButton,
                      shadowStyles,
                      { backgroundColor: getSecondaryColor(mainColor) },
                    ]}
                  >
                    <CustomIcon
                      name="icon_play_video_with_offset"
                      color={mainColor}
                      size={mvs(23)}
                      onPress={() => Linking.openURL(link)}
                      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                    />
                  </View>
                ) : null}
              </View>
            ) : null}
            <>
              <CustomText
                style={[
                  styles.liveFeedPostMessage,
                  { fontSize: liveFeedPostMessageFontSize },
                ]}
                textAlign={textAlign}
                weight="Regular"
              >
                {message}
              </CustomText>
              {title ? (
                <CustomText
                  style={[
                    styles.liveFeedPostTitle,
                    { fontSize: liveFeedPostTitleFontSize },
                  ]}
                  weight="SemiBold"
                  textAlign={textAlign}
                >
                  {title}
                </CustomText>
              ) : null}
              {description ? (
                <CustomText
                  style={[
                    styles.liveFeedPostMessage,
                    { fontSize: liveFeedPostMessageFontSize },
                  ]}
                  textAlign={textAlign}
                  weight="Regular"
                >
                  {description}
                </CustomText>
              ) : null}
            </>
          </>
        );
      } else if (
        (liveFeedPost as LiveFeedPostSurveyModel).question &&
        type !== 'resultat'
      ) {
        return <LiveFeedSurvey liveFeedPost={liveFeedPost} />;
      } else if (type === 'resultat') {
        const survey = liveFeedPost as LiveFeedPostSurveyResultsModel;
        const question = survey.question;
        const {
          resultat1: result1,
          resultat2: result2,
          resultat3: result3,
          resultat4: result4,
          resultat5: result5,
        } = survey;
        const results = [result1, result2, result3, result4, result5];
        const resultsPercents = parseSurveyResults(survey);
        const maxPercent = Math.max(...resultsPercents);
        contents = (
          <>
            {picture ? (
              <Image
                style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
                source={{ uri: picture }}
              />
            ) : null}
            <>
              <CustomText
                style={[
                  styles.liveFeedPostMessage,
                  { fontSize: liveFeedPostMessageFontSize },
                ]}
                textAlign={textAlign}
                weight="Regular"
              >
                {message}
              </CustomText>
              <CustomText
                style={[
                  styles.liveFeedPostTitle,
                  { fontSize: liveFeedPostTitleFontSize },
                ]}
                weight="SemiBold"
                textAlign={textAlign}
              >
                {question}
              </CustomText>
              <>
                {results.map((result, index) =>
                  result ? (
                    <View
                      key={`${result}-${index}`}
                      style={[
                        styles.liveFeedPostSurveyResultContainer,
                        { borderRadius: mvs(divRadius) },
                      ]}
                    >
                      <View
                        style={[
                          styles.liveFeedPostSurveyResultBar,
                          computeSurveyResultBarStyles(resultsPercents[index]),
                          { borderRadius: mvs(divRadius) },
                        ]}
                      />
                      <CustomText
                        style={[styles.liveFeedPostSurveyResultText]}
                        weight={
                          resultsPercents[index] === maxPercent ? 'SemiBold' : 'Regular'
                        }
                      >
                        {result}
                      </CustomText>
                    </View>
                  ) : null,
                )}
              </>
            </>
          </>
        );
      }

      return (
        <LiveFeedPostWrapper liveFeedPost={liveFeedPost}>{contents}</LiveFeedPostWrapper>
      );
    },
    [
      divRadius,
      liveFeedPostMessageFontSize,
      textAlign,
      mainColor,
      shadowStyles,
      liveFeedPostTitleFontSize,
    ],
  );

  const liveFeedListKeyExtractor = useCallback(
    (item: LiveFeedPostModel) => `${item.idlivefeeds}`,
    [],
  );

  return (
    <ScreenLayout
      headerContents={
        <>
          {menuIcon}
          {tabName}
        </>
      }
    >
      <FlatList
        ref={liveFeedListRef}
        data={liveFeed}
        renderItem={renderLiveFeedPost}
        keyExtractor={liveFeedListKeyExtractor}
        contentContainerStyle={[
          styles.body,
          {
            paddingHorizontal: bodyHorizontalPadding,
            maxWidth: maxWidth,
          },
        ]}
        showsVerticalScrollIndicator={false}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      />
    </ScreenLayout>
  );
}

const LiveFeedPostWrapper = ({
  liveFeedPost,
  children,
  bottomActionButton,
}: {
  liveFeedPost: LiveFeedPostModel;
  children: ReactNode;
  bottomActionButton?: ReactElement | null;
}) => {
  const author = liveFeedPost.auteur_nom?.trim() ?? '';
  const avatar = liveFeedPost.auteur_photo;
  const formattedDate = liveFeedPost.date_publication
    ? formatFromNow(dateFromSQLUTC(liveFeedPost.date_publication))
    : '';
  const picture = liveFeedPost.photo;
  const youtubeUrl = liveFeedPost.urlyoutube;
  const link = liveFeedPost.lien;
  const shareMessage = liveFeedPost.message;
  const appConfig = useAppSelector(selectAppConfig);
  const shareUrl = link ?? youtubeUrl ?? picture ?? appConfig.urlSiteRadio ?? undefined;
  const mainColor = useAppSelector(selectMainColor);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);
  const shadowStyles = useShadowStyles();

  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const avatarLeftOffset = hasMargin ? mvs(-13) : 0;

  // dynamic font sizes
  const liveFeedPostAuthorFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const liveFeedPostDateFontSize = useAdjustedFontSize(mvs(14, 0.35));
  const liveFeedPostButtonLinkTitleFontSize = useAdjustedFontSize(mvs(20, 0.35));

  const dynamicHeaderStyles = (align: TextAlign): ViewStyle => {
    switch (align) {
      case 'center':
        return { justifyContent: 'center' };
      case 'right':
        return { justifyContent: 'flex-end' };
      default:
        return {};
    }
  };

  return (
    <>
      <View style={[styles.liveFeedPost, { borderRadius: mvs(divRadius) }]}>
        <View style={[styles.liveFeedPostHeader, dynamicHeaderStyles(textAlign)]}>
          <CustomText
            numberOfLines={1}
            style={[styles.liveFeedPostAuthor, { fontSize: liveFeedPostAuthorFontSize }]}
            weight="SemiBold"
          >
            {author}
          </CustomText>
          <CustomText
            numberOfLines={1}
            style={[styles.liveFeedPostDate, { fontSize: liveFeedPostDateFontSize }]}
            weight="Regular"
          >
            - {formattedDate}
          </CustomText>
        </View>
        <View style={styles.liveFeedPostContents}>{children}</View>
      </View>
      <Avatar
        source={avatar ? { uri: avatar } : {}}
        rounded
        size={mvs(57)}
        containerStyle={[
          styles.liveFeedPostAvatarContainer,
          shadowStyles,
          { left: avatarLeftOffset },
        ]}
      />
      <View style={styles.liveFeedPostBottomActions}>
        {bottomActionButton ? (
          bottomActionButton
        ) : link ? (
          <Button
            containerStyle={[
              styles.liveFeedPostButtonLinkContainer,
              { borderRadius: mvs(divRadius) },
              shadowStyles,
            ]}
            buttonStyle={[styles.liveFeedPostButtonLinkButton, shadowStyles]}
            titleStyle={[
              styles.liveFeedPostButtonLinkTitle,
              {
                fontFamily: getFontFamily(fontFamily, 'SemiBold'),
                fontSize: liveFeedPostButtonLinkTitleFontSize,
              },
            ]}
            ViewComponent={LinearGradient}
            linearGradientProps={{
              // gradient initilalized with a single color
              // prepared for future addition of a secondary color.
              colors: [mainColor, mainColor],
              start: { x: 0, y: 0.5 },
              end: { x: 1, y: 0.5 },
            }}
            type={'solid'}
            title={i18n.t('liveFeed.openLink')}
            titleProps={{ numberOfLines: 1 }}
            onPress={() => Linking.openURL(link)}
          />
        ) : null}
        <View
          style={[
            styles.liveFeedPostShareButton,
            shadowStyles,
            { backgroundColor: getSecondaryColor(mainColor) },
            IS_WEB ? { pointerEvents: 'none' } : undefined,
          ]}
        >
          <CustomIcon
            name="icon_share_with_offset"
            color={mainColor}
            size={mvs(22, 0.35)}
            onPress={() => {
              Share.share({
                message:
                  IS_ANDROID && shareUrl ? `${shareMessage} ${shareUrl}` : shareMessage,
                url: shareUrl,
              });
            }}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          />
        </View>
      </View>
    </>
  );
};

const LiveFeedSurvey = ({ liveFeedPost }: { liveFeedPost: LiveFeedPostModel }) => {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(selectAppConfig);
  const [isSubmittingVote, setIsSubmittingVote] = useState(false);
  const [isVoteSubmitted, setIsVoteSubmitted] = useState(false);
  const [choice, setChoice] = useState<LiveFeedPostSurveyAnswer | null>(null);
  const [isSurveyButtonDisabled, setIsSurveyButtonDisabled] = useState(true);
  const mainColor = useAppSelector(selectMainColor);
  const fontFamily = useAppSelector(selectFontFamily);
  const divRadius = useAppSelector(selectDivRadius);
  const textAlign = useAppSelector(selectTextAlign);

  // dynamic font sizes
  const liveFeedPostMessageFontSize = useAdjustedFontSize(mvs(16, 0.35));
  const liveFeedPostTitleFontSize = useAdjustedFontSize(mvs(18, 0.35));
  const liveFeedPostButtonLinkTitleFontSize = useAdjustedFontSize(mvs(20, 0.35));

  const id = liveFeedPost.idlivefeeds;
  const message = liveFeedPost.message?.trim() ?? '';
  const picture = liveFeedPost.photo;
  const survey = liveFeedPost as LiveFeedPostSurveyModel;
  const isSurveyActive = survey.sondageactif === '1';
  const question = survey.question;
  const {
    reponse1: answer1,
    reponse2: answer2,
    reponse3: answer3,
    reponse4: answer4,
    reponse5: answer5,
  } = survey;
  const answers = [answer1, answer2, answer3, answer4, answer5];
  const answersChoices: LiveFeedPostSurveyAnswer[] = ['1', '2', '3', '4', '5'];

  useEffect(() => {
    setIsSurveyButtonDisabled(choice === null);
  }, [choice]);

  const onVoteSurvey = useCallback(
    async (
      liveFeedPostId: LiveFeedPostSurveyModel['idlivefeeds'],
      answer: LiveFeedPostSurveyAnswer,
    ) => {
      if (!appConfig.urlSiteRadio) {
        return;
      }
      try {
        setIsSubmittingVote(true);
        await sendVoteLiveFeedSurvey(appConfig.urlSiteRadio, liveFeedPostId, answer);
        setIsVoteSubmitted(true);
      } catch (err) {
        dispatch(
          showAlert({
            key: 'send-survey-error',
            type: 'warning',
            text: i18n.t('alert.sendSurveyError'),
            closable: false,
            autoCloseAfterMs: 2000,
          }),
        );
      } finally {
        setIsSubmittingVote(false);
      }
    },
    [appConfig, dispatch],
  );

  return (
    <LiveFeedPostWrapper
      liveFeedPost={liveFeedPost}
      bottomActionButton={
        isSurveyActive && !isVoteSubmitted ? (
          <Button
            containerStyle={[
              styles.liveFeedPostButtonLinkContainer,
              { borderRadius: mvs(divRadius) },
            ]}
            buttonStyle={styles.liveFeedPostButtonLinkButton}
            titleStyle={[
              styles.liveFeedPostButtonLinkTitle,
              {
                fontFamily: getFontFamily(fontFamily, 'SemiBold'),
                fontSize: liveFeedPostButtonLinkTitleFontSize,
              },
            ]}
            ViewComponent={LinearGradient}
            linearGradientProps={{
              // gradient initialized with a single color
              // prepared for future addition of a secondary color.
              colors: isSurveyButtonDisabled
                ? [getSecondaryColor(mainColor), getSecondaryColor(mainColor)]
                : [mainColor, mainColor],
              start: { x: 0, y: 0.5 },
              end: { x: 1, y: 0.5 },
            }}
            type={'solid'}
            title={i18n.t(
              isSubmittingVote ? 'liveFeed.submittingSurvey' : 'liveFeed.submitSurvey',
            )}
            titleProps={{ numberOfLines: 1 }}
            disabled={isSurveyButtonDisabled || IS_WEB}
            disabledTitleStyle={styles.liveFeedPostButtonLinkTitleDisabled}
            onPress={() => choice && onVoteSurvey(id, choice)}
          />
        ) : null
      }
    >
      {picture ? (
        <Image
          style={[styles.liveFeedPostPicture, { borderRadius: mvs(divRadius) }]}
          source={{ uri: picture }}
        />
      ) : null}
      <>
        <CustomText
          style={[styles.liveFeedPostMessage, { fontSize: liveFeedPostMessageFontSize }]}
          weight="Regular"
          textAlign={textAlign}
        >
          {message}
        </CustomText>
        <CustomText
          style={[styles.liveFeedPostTitle, { fontSize: liveFeedPostTitleFontSize }]}
          weight="SemiBold"
          textAlign={textAlign}
        >
          {question}
        </CustomText>
        {isSurveyActive ? (
          !isVoteSubmitted ? (
            <>
              {answers.map((answer, index) =>
                answer ? (
                  <ListItem.CheckBox
                    key={`${answer}-${index}`}
                    containerStyle={[
                      styles.liveFeedPostSurveyAnswerContainer,
                      { borderRadius: mvs(divRadius) },
                      choice === answersChoices[index]
                        ? [
                            styles.liveFeedPostSurveyAnswerContainerSelected,
                            {
                              borderColor: mainColor,
                              backgroundColor: getSecondaryColor(mainColor),
                            },
                          ]
                        : null,
                    ]}
                    textStyle={[
                      styles.liveFeedPostSurveyAnswerText,
                      choice === answersChoices[index]
                        ? styles.liveFeedPostSurveyAnswerTextSelected
                        : null,
                      { fontFamily: getFontFamily(fontFamily, 'Regular') },
                    ]}
                    title={answer}
                    checked={choice === answersChoices[index]}
                    checkedIcon={
                      <FontAwesomeIcon
                        size={25}
                        secondaryColor={'#FF0000'}
                        color={mainColor}
                        icon={faDotCircle}
                      />
                    }
                    uncheckedIcon={
                      <FontAwesomeIcon
                        size={25}
                        secondaryColor={'#FF0000'}
                        color={'#D9D9D9'}
                        icon={faCircle}
                      />
                    }
                    checkedColor={mainColor}
                    uncheckedColor={'#D9D9D9'}
                    onPress={() => setChoice(answersChoices[index])}
                  />
                ) : null,
              )}
            </>
          ) : (
            <View
              style={[
                styles.liveFeedPostSurveySubmittedMessage,
                { borderRadius: mvs(divRadius) },
              ]}
            >
              <CustomText
                style={styles.liveFeedPostSurveySubmittedMessageText}
                weight="SemiBold"
                textAlign={textAlign}
              >
                {i18n.t('liveFeed.submittedSurvey')}
              </CustomText>
            </View>
          )
        ) : (
          <View
            style={[
              styles.liveFeedPostSurveyClosedMessage,
              {
                backgroundColor: getSecondaryColor(mainColor),
                borderRadius: mvs(divRadius),
              },
            ]}
          >
            <CustomText
              style={[styles.liveFeedPostSurveyClosedMessageText, { color: mainColor }]}
              weight="SemiBold"
              textAlign={textAlign}
            >
              {i18n.t('liveFeed.closedSurvey')}
            </CustomText>
          </View>
        )}
      </>
    </LiveFeedPostWrapper>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-start',
  },
  title: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
    lineHeight: mvs(30),
  },
  body: {
    paddingTop: mvs(18),
    width: isTablet ? mvs(450, 0.35) : '100%',
    alignSelf: 'center',
  },
  liveFeedPost: {
    backgroundColor: '#fff',
    paddingTop: mvs(15),
    paddingBottom: mvs(35),
    marginBottom: mvs(45),
    marginTop: 10,
  },
  liveFeedPostHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: mvs(55),
    paddingRight: mvs(35),
  },
  liveFeedPostAuthor: {
    color: '#9C9C9C',
  },
  liveFeedPostDate: {
    color: '#9C9C9C',
    marginLeft: mvs(3),
    flex: 1,
  },
  liveFeedPostContents: {
    paddingRight: mvs(26),
    paddingLeft: isTablet ? mvs(55) : mvs(26),
    paddingTop: mvs(12),
  },
  liveFeedPostMessage: {
    color: '#9C9C9C',
  },
  liveFeedPostTitle: {
    color: '#474747',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostPictureContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  liveFeedPostPicture: {
    backgroundColor: '#EEE',
    width: '100%',
    aspectRatio: 16 / 9,
    resizeMode: 'cover',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostVideoButton: {
    width: mvs(51),
    aspectRatio: 1,
    borderRadius: mvs(26),
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  liveFeedPostTextLink: {
    textDecorationLine: 'underline',
    marginVertical: mvs(9, 0.35),
  },
  liveFeedPostSurveyAnswerContainer: {
    backgroundColor: '#fff',
    paddingVertical: mvs(12),
    paddingHorizontal: mvs(10),
    marginBottom: mvs(5, 0.35),
    borderWidth: 1,
    borderColor: '#EEEEEE',
  },
  liveFeedPostSurveyAnswerText: {
    fontSize: mvs(16, 0.35),
    fontWeight: 'normal',
    color: '#9C9C9C',
  },
  liveFeedPostSurveyAnswerTextSelected: {
    color: '#474747',
  },
  liveFeedPostSurveySubmittedMessage: {
    backgroundColor: '#2ED57312',
    minHeight: mvs(45, 0.35),
    alignItems: 'center',
    justifyContent: 'center',
  },
  liveFeedPostSurveySubmittedMessageText: {
    fontSize: mvs(16, 0.35),
    color: '#2ED573',
    paddingHorizontal: mvs(20),
    paddingVertical: mvs(10),
    textAlign: 'center',
  },
  liveFeedPostSurveyClosedMessage: {
    minHeight: mvs(45, 0.35),
    alignItems: 'center',
    justifyContent: 'center',
  },
  liveFeedPostSurveyClosedMessageText: {
    fontSize: mvs(16, 0.35),
    paddingHorizontal: mvs(20),
    paddingVertical: mvs(10),
    textAlign: 'center',
  },
  liveFeedPostSurveyResultContainer: {
    backgroundColor: '#2ED5731A',
    paddingVertical: mvs(12),
    paddingHorizontal: mvs(10),
    marginBottom: mvs(5, 0.35),
    justifyContent: 'center',
  },
  liveFeedPostSurveyResultBar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#2ED573',
  },
  liveFeedPostSurveyResultText: {
    fontSize: mvs(16, 0.35),
    color: '#474747',
  },
  liveFeedPostAvatarContainer: {
    backgroundColor: '#FFA502',
    borderWidth: mvs(3),
    borderColor: '#fff',
    position: 'absolute',
    top: mvs(-15),
  },
  liveFeedPostBottomActions: {
    position: 'absolute',
    bottom: mvs(23),
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingRight: mvs(32),
    paddingLeft: isTablet ? mvs(61) : mvs(32),
  },
  liveFeedPostButtonLinkContainer: {
    marginRight: mvs(7),
    flex: 1,
  },
  liveFeedPostButtonLinkButton: {
    height: mvs(46),
  },
  liveFeedPostButtonLinkTitle: {
    color: '#fff',
  },
  liveFeedPostButtonLinkTitleDisabled: {
    color: '#ffffff80',
  },
  liveFeedPostShareButton: {
    width: mvs(46, 0.35),
    aspectRatio: 1,
    borderRadius: mvs(23),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
