import { ReactNode } from 'react';
import { StyleProp, ViewProps, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { COLORS } from '../../constants';
import { GradientDirection } from '../../models';

interface GradientBackgroundProps extends ViewProps {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  colors?: string[];
  invert?: boolean; // flag to invert the gradient direction
  direction?: GradientDirection;
}

const DEFAULT_COLORS = [COLORS.BLACK_50, COLORS.BLACK_0];

export const GradientBackground = ({
  children,
  style,
  colors = DEFAULT_COLORS,
  invert = false,
  direction = 'bottom',
}: GradientBackgroundProps) => {
  const directions = {
    bottom: { x0: 0.5, y0: 0, x1: 0.5, y1: 1 },
    'bottom-right': { x0: 0, y0: 0, x1: 1, y1: 1 },
    right: { x0: 0, y0: 0.5, x1: 1, y1: 0.5 },
    'bottom-left': { x0: 1, y0: 0, x1: 0, y1: 1 },
  };

  const { x0, y0, x1, y1 } = directions[direction];

  const startPoint = invert ? { x: x1, y: y1 } : { x: x0, y: y0 };
  const endPoint = invert ? { x: x0, y: y0 } : { x: x1, y: y1 };

  return (
    <LinearGradient
      style={style}
      colors={colors}
      start={{ x: startPoint.x, y: startPoint.y }}
      end={{ x: endPoint.x, y: endPoint.y }}
    >
      {children}
    </LinearGradient>
  );
};
