import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './reducer'; // Ensure this is your combined root reducer

export const store = configureStore({
  reducer: rootReducer, // Directly use the rootReducer
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
