import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AlertData {
  key: string;
  text: string;
  type: 'info' | 'warning' | 'danger';
  closable: boolean;
  autoCloseAfterMs?: number;
}

export type ModalSliceState = {
  alert: AlertData | null;
};

const initialState: ModalSliceState = {
  alert: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showAlert: (state, { payload: alert }: PayloadAction<AlertData>) => {
      state.alert = alert;
    },
    hideAlert: (state, { payload: key }: PayloadAction<AlertData['key']>) => {
      if (state.alert?.key === key) {
        state.alert = null;
      }
    },
  },
});
export const { showAlert, hideAlert } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
