import { useWindowDimensions } from 'react-native';

import useOrientation from './useOrientation';
import { isTablet } from '../constants';

export default function useDrawerWidth(): number {
  const windowWidth = useWindowDimensions().width;
  const orientation = useOrientation();
  if (isTablet) {
    if (orientation === 'PORTRAIT') {
      return windowWidth * 0.5;
    } else {
      return windowWidth * 0.33;
    }
  } else {
    return windowWidth * 0.87;
  }
}
