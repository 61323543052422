import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Dimensions, StatusBar, StyleSheet, Text, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';

import { useAppDispatch, useAppSelector } from './app/hooks';
import { store } from './app/store';
import { COLORS } from './constants';
import { selectAppConfig, selectStatus } from './features/appconfig/store/selectors';
import { setAppConfig, setStatus } from './features/appconfig/store/slice';
import { setBearerToken } from './features/bearertoken/store/slice';
import { setRawStreams } from './features/rawStream/store/slice';
import i18n from './localization/i18n';
import { AppConfigModel } from './models';
import Navigation from './navigation';
import * as RootNavigation from './RootNavigation';
import { PlayerBackgroundComponent } from './sdk/components/Player/PlayerBackgroundComponent';
import useColorScheme from './sdk/hooks/useColorScheme';

function App() {
  const colorScheme = useColorScheme();
  const appConfig = useAppSelector(selectAppConfig);
  const appStatus = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    library.add(far, fab, fas);
    window.parent.postMessage({ type: 'app-ready' }, '*');
    const handleMessage = (event: {
      data: {
        config?: AppConfigModel;
        navigate?: number;
        openMenu?: boolean;
        bearerToken?: string;
      };
    }) => {
      if (event.data && event.data.config) {
        i18n.locale = event.data.config.lang;
        dispatch(setAppConfig(event.data.config));
        if (event.data.config.streams) {
          dispatch(setRawStreams(event.data.config.streams));
        }
      }
      if (
        event.data &&
        event.data.navigate !== undefined &&
        event.data.navigate !== null
      ) {
        RootNavigation.navigate('tab' + event.data.navigate);
        setCurrentTab(event.data.navigate);
      }
      if (event.data && event.data.openMenu) {
        RootNavigation.openMenu();
      }
      if (event.data && event.data.bearerToken) {
        dispatch(setBearerToken(event.data.bearerToken));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);

  useEffect(() => {
    if (appConfig !== null) {
      dispatch(setStatus(appConfig.status));
    }
    if (currentTab !== 0) {
      const resetScreen = setTimeout(() => {
        RootNavigation.navigate('tab' + currentTab);
      }, 1000);
      return () => {
        clearTimeout(resetScreen);
      };
    }
  }, [appConfig, currentTab, dispatch]);

  if (
    appConfig === null ||
    appStatus === 'desactivee' ||
    appStatus === 'deleted' ||
    appStatus === 'terminee'
  ) {
    return (
      <View
        style={[
          {
            height: Dimensions.get('window').height,
            width: Dimensions.get('window').width,
          },
          styles.container,
        ]}
      >
        <Text style={styles.centeredText}>
          {appConfig === null ? 'Loading...' : i18n.t('errorSplashScreen.appDisabled')}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={{
        height: Dimensions.get('window').height,
        width: Dimensions.get('window').width,
        padding: 9,
      }}
    >
      <SafeAreaProvider>
        <PlayerBackgroundComponent />
        <Navigation
          colorScheme={colorScheme}
          navigationRef={RootNavigation.navigationRef}
        />
        <StatusBar
          animated={true}
          barStyle={'light-content'}
          translucent={true}
          backgroundColor={COLORS.TRANSPARENT}
        />
      </SafeAreaProvider>
    </View>
  );
}

export function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
