import { useAppSelector } from '../app/hooks';
import { selectFontSize } from '../features/appconfig/store/selectors';

export const useAdjustedFontSize = (baseSize: number) => {
  const selectedFontSizeLevel = useAppSelector(selectFontSize);

  const fontSizeAdjustmentMap = {
    1: 0.75,
    2: 0.9,
    3: 1,
    4: 1.1,
    5: 1.2,
  };

  return baseSize * fontSizeAdjustmentMap[selectedFontSizeLevel];
};
