import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataState } from '../../../constants';
import { LiveFeedPostModel } from '../../../models';

export type LiveFeedSliceState = {
  liveFeed: {
    data: LiveFeedPostModel[];
    state: DataState;
  };
};

const initialState: LiveFeedSliceState = {
  liveFeed: { data: [], state: DataState.NOT_LOADED },
};

const liveFeedSlice = createSlice({
  name: 'liveFeed',
  initialState,
  reducers: {
    setLiveFeedLoading: state => {
      state.liveFeed = { ...state.liveFeed, state: DataState.LOADING };
    },
    setLiveFeedError: state => {
      state.liveFeed = { ...state.liveFeed, state: DataState.ERROR };
    },
    setLiveFeed: (state, { payload }: PayloadAction<LiveFeedPostModel[]>) => {
      state.liveFeed = { data: payload, state: DataState.LOADED };
    },
  },
});
export const { setLiveFeedLoading, setLiveFeedError, setLiveFeed } =
  liveFeedSlice.actions;
export const liveFeedReducer = liveFeedSlice.reducer;
