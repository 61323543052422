import { setLiveFeed, setLiveFeedError, setLiveFeedLoading } from './slice';
import { getLiveFeedPosts } from '../../../api/radioWebsiteApi';
import { AppThunk } from '../../../app/store';
import { AppConfigModel } from '../../../models';

export const fetchLiveFeed =
  (appConfig: AppConfigModel, bearerToken: string): AppThunk =>
  async dispatch => {
    if (!appConfig.urlSiteRadio) {
      return;
    }

    dispatch(setLiveFeedLoading());

    try {
      const result = await getLiveFeedPosts(appConfig.urlSiteRadio, bearerToken);
      dispatch(setLiveFeed(result));
    } catch (e) {
      dispatch(setLiveFeedError());
    }
  };
