import { StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { CustomIcon } from './CustomIcon';
import { useAppDispatch } from '../../app/hooks';
import { COLORS } from '../../constants/Colors';
import { showAlert } from '../../features/modal/store/slice';
import i18n from '../../localization/i18n';

export function SleepTimer() {
  const dispatch = useAppDispatch();

  return (
    <TouchableOpacity
      onPress={() =>
        dispatch(
          showAlert({
            key: 'only-mobile',
            type: 'info',
            text: i18n.t('alert.onlyMobile'),
            closable: false,
            autoCloseAfterMs: 2000,
          }),
        )
      }
    >
      <View style={[styles.iconContainer, {}]}>
        <CustomIcon name="icon-moon-sharp" size={24} color={COLORS.WHITE} />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1000,
    borderWidth: 1,
    zIndex: 10,
    overflow: 'hidden',
  },
});
