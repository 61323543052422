import { createSlice } from '@reduxjs/toolkit';

export type BearerTokenSliceState = {
  bearerToken: string;
};

const initialState: BearerTokenSliceState = {
  bearerToken: '',
};

const bearerTokenSlice = createSlice({
  name: 'bearerToken',
  initialState,
  reducers: {
    setBearerToken(state, action) {
      state.bearerToken = action.payload;
    },
  },
});
export const { setBearerToken } = bearerTokenSlice.actions;
export const bearerTokenReducer = bearerTokenSlice.reducer;
