import { Button } from '@rneui/themed';
import { noop } from 'lodash';
import {
  Image,
  ImageBackground,
  Linking,
  Modal,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import { FONTS } from '../constants';
import i18n from '../localization/i18n';
import { CustomIcon, CustomIconProps } from '../sdk/components/CustomIcon';
import { FloatingExitButton } from '../sdk/components/FloatingExitButton';
import { isTablet } from '../sdk/constants';
import useOrientation from '../sdk/hooks/useOrientation';

export interface AboutModalScreenProps {
  onCancel: () => void;
  visible?: boolean;
}

interface AboutFeature {
  title: string;
  subtitle: string;
  icon: CustomIconProps['name'];
}

export default function AboutModalScreen({
  onCancel,
  visible = false,
}: AboutModalScreenProps) {
  const features: AboutFeature[] = [
    {
      title: i18n.t('aboutModal.featureRadioTitle'),
      subtitle: i18n.t('aboutModal.featureRadioSubtitle'),
      icon: 'icon_about_radio',
    },
    {
      title: i18n.t('aboutModal.featureWebsiteTitle'),
      subtitle: i18n.t('aboutModal.featureWebsiteSubtitle'),
      icon: 'icon_about_website',
    },
    {
      title: i18n.t('aboutModal.featureMobileTitle'),
      subtitle: i18n.t('aboutModal.featureMobileSubtitle'),
      icon: 'icon_about_mobile',
    },
    {
      title: i18n.t('aboutModal.featurePlayerTitle'),
      subtitle: i18n.t('aboutModal.featurePlayerSubtitle'),
      icon: 'icon_about_player',
    },
  ];

  const windowWidth = useWindowDimensions().width;
  const orientation = useOrientation();
  const isTabletLandscape = orientation === 'LANDSCAPE' && isTablet;

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={visible}
      statusBarTranslucent={true}
      onRequestClose={() => onCancel()}
    >
      <ImageBackground
        style={[
          styles.modalView,
          { width: windowWidth },
          { paddingTop: isTabletLandscape ? mvs(69) : mvs(85) },
        ]}
        source={require('../assets/images/about-background.png')}
        resizeMode={'stretch'}
      >
        {isTablet ? (
          <Image
            style={styles.logo}
            source={require('../assets/images/about-logo2.png')}
          />
        ) : (
          <Image
            style={styles.logo}
            source={require('../assets/images/about-logo.png')}
          />
        )}
        <View
          style={[
            styles.featureList,
            isTabletLandscape && {
              flexDirection: 'row',
              width: '100%',
              flexWrap: 'wrap',
              marginHorizontal: mvs(60),
              marginTop: mvs(15),
            },
          ]}
        >
          {features.map((item, index) => (
            <View
              key={index}
              style={[
                styles.featureContainer,
                isTabletLandscape && {
                  width: '50%',
                },
              ]}
            >
              <CustomIcon name={item.icon} onPress={noop} style={styles.featureIcon} />
              <View>
                <Text style={styles.featureTitle}>{item.title}</Text>
                <Text style={styles.featureSubtitle}>{item.subtitle}</Text>
              </View>
            </View>
          ))}
          {!isTablet && (
            <View style={styles.cta}>
              <Button
                containerStyle={styles.ctaButtonContainer}
                buttonStyle={styles.ctaButton}
                titleStyle={styles.ctaButtonTitle}
                ViewComponent={LinearGradient}
                linearGradientProps={{
                  colors: ['#FF9150', '#FF4757'],
                  start: { x: 0, y: 0.5 },
                  end: { x: 1, y: 0.5 },
                }}
                title={i18n.t('aboutModal.cta')}
                onPress={() => Linking.openURL(process.env.REACT_APP_MARKETING_URL)}
              />
            </View>
          )}
        </View>
        {isTablet && (
          <View style={styles.cta}>
            <Button
              containerStyle={styles.ctaButtonContainer}
              buttonStyle={styles.ctaButton}
              titleStyle={styles.ctaButtonTitle}
              ViewComponent={LinearGradient}
              linearGradientProps={{
                colors: ['#FF9150', '#FF4757'],
                start: { x: 0, y: 0.5 },
                end: { x: 1, y: 0.5 },
              }}
              title={i18n.t('aboutModal.cta')}
              onPress={() => Linking.openURL(process.env.REACT_APP_MARKETING_URL)}
            />
          </View>
        )}
      </ImageBackground>
      <FloatingExitButton
        containerStyle={styles.exitButtonContainer}
        onPress={() => onCancel()}
      />
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalView: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: isTablet ? mvs(30) : 30,
    paddingTop: isTablet ? mvs(85) : 85,
    paddingBottom: isTablet ? mvs(40) : 40,
  },
  logo: {
    marginBottom: isTablet ? mvs(30) : 10,
    alignSelf: 'center',
    width: '100%',
    aspectRatio: 255 / 65,
  },
  featureList: {
    flex: isTablet ? undefined : 1,
    justifyContent: isTablet ? undefined : 'space-around',
  },
  featureContainer: {
    backgroundColor: 'transparent',
    padding: 0,
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: isTablet ? mvs(22.5) : undefined,
  },
  featureIcon: {
    backgroundColor: '#fff',
    width: isTablet ? mvs(43) : 43,
    height: isTablet ? mvs(43) : 43,
    borderRadius: isTablet ? mvs(6) : 6,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: isTablet ? mvs(12) : 12,
    pointerEvents: 'none',
  },
  featureTitle: {
    fontFamily: FONTS.Quicksand_700Bold,
    fontSize: isTablet ? mvs(22) : 22,
    maxWidth: isTablet ? undefined : 250,
    color: '#fff',
  },
  featureSubtitle: {
    fontFamily: FONTS.Quicksand_400Regular,
    fontSize: isTablet ? mvs(16) : 16,
    maxWidth: isTablet ? undefined : 250,
    color: '#FFFFFFCC',
    paddingRight: isTablet ? undefined : 40,
  },
  exitButtonContainer: {
    right: isTablet ? mvs(28) : 28,
    top: isTablet ? mvs(45) : 45,
  },
  cta: {
    position: isTablet ? 'absolute' : 'relative',
    right: isTablet ? mvs(33.5) : undefined,
    bottom: isTablet ? mvs(39) : undefined,
    shadowColor: '#000',
    shadowOffset: {
      width: isTablet ? mvs(10) : 10,
      height: isTablet ? mvs(10) : 10,
    },
    shadowOpacity: 0.25,
    shadowRadius: mvs(30),
    elevation: 30,
    alignSelf: isTablet ? undefined : 'center',
  },
  ctaButtonContainer: {
    borderRadius: isTablet ? mvs(3) : 3,
  },
  ctaButton: {
    paddingVertical: isTablet ? mvs(12) : 14,
    paddingHorizontal: isTablet ? mvs(15) : 16.75,
    width: isTablet ? undefined : '100%',
  },
  ctaButtonTitle: {
    fontFamily: FONTS.Quicksand_400Regular,
    fontSize: isTablet ? mvs(16) : 20,
    color: '#fff',
  },
});
