import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Some endpoints are "slow" to return synced data, wait to be sure data is synced
 *
 * Ex: post some data, then fetch immediately after the post endpoint responded: fetched data can be outdated
 */
export const API_SYNC_DELAY_MS = 250;

interface ApiCallParams {
  url: string;
  params?: AxiosRequestConfig['params'];
  method?: AxiosRequestConfig['method'];
  data?: unknown;
  headers?: AxiosRequestConfig['headers'];
}

export async function makeApiCall<T>({
  url,
  params,
  method = 'get',
  data,
  headers,
}: ApiCallParams): Promise<AxiosResponse<Readonly<T>>> {
  return axios({
    url,
    params,
    method,
    data,
    headers,
    timeout: Number(process.env.REACT_APP_API_REQUESTS_TIMEOUT_MS),
  });
}

export async function sendFormData({
  url,
  method = 'post',
  data,
}: ApiCallParams & { data: FormData }): ReturnType<typeof fetch> {
  const options = {
    method,
    body: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    throw response;
  }

  return response;
}

/**
 * Run some validity checks client-side because some endpoints are unreliable/non-standard.
 *
 * These endpoints do not return appropriate http codes, like they should return 4xx instead of always 2xx in cases like errors
 * They also return html content instead of json in some cases like errors
 */
export function checkNonStandardApiReturn<T extends { code: 'success' | 'error' }>(
  res: AxiosResponse<T>,
) {
  if (res.data.code !== 'success') {
    throw new Error(`Api call failed with url: ${res.config.url}`);
  }
}
