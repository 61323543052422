export const FONTS = {
  Quicksand_400Regular: 'Quicksand-Regular',
  Quicksand_700Bold: 'Quicksand-Bold',
  Quicksand_600SemiBold: 'Quicksand-SemiBold',
  Poppins_400Regular: 'Poppins-Regular',
  Poppins_700Bold: 'Poppins-Bold',
  Poppins_600SemiBold: 'Poppins-SemiBold',
  DancingScript_400Regular: 'DancingScript-Regular',
  DancingScript_700Bold: 'DancingScript-Bold',
  DancingScript_600SemiBold: 'DancingScript-SemiBold',
  Comfortaa_400Regular: 'Comfortaa-Regular',
  Comfortaa_700Bold: 'Comfortaa-Bold',
  Comfortaa_600SemiBold: 'Comfortaa-SemiBold',
  EBGaramond_400Regular: 'EBGaramond-Regular',
  EBGaramond_700Bold: 'EBGaramond-Bold',
  EBGaramond_600SemiBold: 'EBGaramond-SemiBold',
  Fredoka_400Regular: 'Fredoka-Regular',
  Fredoka_700Bold: 'Fredoka-Bold',
  Fredoka_600SemiBold: 'Fredoka-SemiBold',
  JosefinSans_400Regular: 'JosefinSans-Regular',
  JosefinSans_700Bold: 'JosefinSans-Bold',
  JosefinSans_600SemiBold: 'JosefinSans-SemiBold',
  Oswald_400Regular: 'Oswald-Regular',
  Oswald_700Bold: 'Oswald-Bold',
  Oswald_600SemiBold: 'Oswald-SemiBold',
  PlayfairDisplay_400Regular: 'PlayfairDisplay-Regular',
  PlayfairDisplay_700Bold: 'PlayfairDisplay-Bold',
  PlayfairDisplay_600SemiBold: 'PlayfairDisplay-SemiBold',
  Raleway_400Regular: 'Raleway-Regular',
  Raleway_700Bold: 'Raleway-Bold',
  Raleway_600SemiBold: 'Raleway-SemiBold',
  Roboto_400Regular: 'Roboto-Regular',
  Roboto_700Bold: 'Roboto-Bold',
  Roboto_600SemiBold: 'Roboto-SemiBold',
  RobotoMono_400Regular: 'RobotoMono-Regular',
  RobotoMono_700Bold: 'RobotoMono-Bold',
  RobotoMono_600SemiBold: 'RobotoMono-SemiBold',
  Solway_400Regular: 'Solway-Regular',
  Solway_700Bold: 'Solway-Bold',
  Solway_600SemiBold: 'Solway-SemiBold',
};
