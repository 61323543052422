import { updateCurrentRadio as update, updateIsHD as updateHD } from './slice';
import { AppThunk } from '../../../app/store';
import { StreamModel } from '../../../models';

export const updateCurrentRadio =
  (stream: StreamModel): AppThunk =>
  dispatch => {
    dispatch(update(stream));
  };

export const updateIsHD =
  (isHD: boolean): AppThunk =>
  dispatch => {
    dispatch(updateHD(isHD));
  };
