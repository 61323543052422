import { useEffect, useState } from 'react';
import { EmitterSubscription, Keyboard } from 'react-native';

import { IS_IOS } from '../constants';

export default function useKeyboardHeight() {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const handleKeyboardShow = e => setKeyboardHeight(e.endCoordinates.height);
    const handleKeyboardHide = () => setKeyboardHeight(0);

    let subscriptions: EmitterSubscription[];

    if (IS_IOS) {
      subscriptions = [
        Keyboard.addListener('keyboardWillShow', handleKeyboardShow),
        Keyboard.addListener('keyboardWillHide', handleKeyboardHide),
      ];
    } else {
      subscriptions = [
        Keyboard.addListener('keyboardDidShow', handleKeyboardShow),
        Keyboard.addListener('keyboardDidHide', handleKeyboardHide),
      ];
    }

    return () => {
      subscriptions.forEach(s => s.remove());
    };
  }, []);

  return keyboardHeight;
}
