import { RootState } from '../../../app/reducer';
import { DataState } from '../../../constants';
import { DedicationModel } from '../../../models';

export const selectTextDedications = ({
  textDedicationReducer,
}: RootState): DedicationModel[] => textDedicationReducer.dedications.data;

export const selectTextDedicationsState = ({
  textDedicationReducer,
}: RootState): DataState => textDedicationReducer.dedications.state;
