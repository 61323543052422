import { ReactNode, useEffect, useState } from 'react';
import {
  ImageBackgroundProps,
  ImageURISource,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';

import { BicolorBackground } from './BicolorBackground';
import { GradientBackground } from './GradientBackground';
import { ImageBackground } from './ImageBackground';
import { useAppSelector } from '../../app/hooks';
import { COLORS } from '../../constants';
import {
  selectBackground,
  selectGradientBackgroundDirection,
  selectHasMargin,
  selectRadioTab,
} from '../../features/appconfig/store/selectors';
import { selectCurrentTrack } from '../../features/currenttrack/store/selectors';
import {
  DEFAULT_BACKGROUND_BLUR_RADIUS,
  IS_WEB,
  isTablet,
  REDUCED_HORIZONTAL_MARGIN,
} from '../constants';

interface Props {
  headerContents: ReactNode;
  children: ReactNode;
  contentStyle?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  backgroundBlurRadius?: ImageBackgroundProps['blurRadius'];
  noBackgroundImage?: boolean;
}

type BackgroundState = ImageURISource | number | null;

export const ScreenLayout = ({
  headerContents,
  children,
  contentStyle,
  headerStyle,
  noBackgroundImage,
}: Props) => {
  const background = useAppSelector(selectBackground);
  const radioTab = useAppSelector(selectRadioTab);
  const currentTrack = useAppSelector(selectCurrentTrack);
  const gradientBackgroundDirection = useAppSelector(selectGradientBackgroundDirection);
  const [currentBackground, setCurrentBackground] = useState<BackgroundState>(null);

  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const horizontalMargin = hasMargin ? mvs(32) : REDUCED_HORIZONTAL_MARGIN;
  const headerStyles = [
    styles.header,
    headerStyle,
    { marginHorizontal: isTablet ? mvs(32) : horizontalMargin },
  ];

  // adds current track cover as a background when showBlurredCover is active
  useEffect(() => {
    return setCurrentBackground(
      radioTab?.showBlurredCover === 'YES' && currentTrack.image
        ? { uri: currentTrack.image }
        : background,
    );
  }, [background, currentTrack, radioTab]);

  // if no image background
  if (!currentBackground || noBackgroundImage) {
    // solid background
    if (radioTab?.backgroundType === 'solid') {
      return (
        <View
          style={[
            styles.content,
            { backgroundColor: radioTab.backgroundColor },
            contentStyle,
          ]}
        >
          <GradientBackground style={IS_WEB ? styles.webOffset : undefined}>
            <SafeAreaView edges={['top']}>
              <View style={headerStyles}>{headerContents}</View>
            </SafeAreaView>
          </GradientBackground>
          {children}
        </View>
      );
    }

    // bicolor background
    if (radioTab?.backgroundType === 'bicolor' && radioTab.backgroundColorSecondary) {
      return (
        <BicolorBackground
          colorBottom={radioTab.backgroundColorSecondary}
          colorTop={radioTab.backgroundColor}
          direction={gradientBackgroundDirection}
        >
          <View style={styles.headerAndContentContainer}>
            <GradientBackground style={IS_WEB ? styles.webOffset : undefined}>
              <SafeAreaView edges={['top']}>
                <View style={headerStyles}>{headerContents}</View>
              </SafeAreaView>
            </GradientBackground>
            {children}
          </View>
        </BicolorBackground>
      );
    }

    if (radioTab?.backgroundType === 'gradient' && radioTab?.backgroundColorSecondary) {
      return (
        <GradientBackground
          colors={[radioTab.backgroundColor, radioTab.backgroundColorSecondary]}
          style={{ flex: 1 }}
          direction={gradientBackgroundDirection}
        >
          <View style={styles.headerAndContentContainer}>
            <GradientBackground style={IS_WEB ? styles.webOffset : undefined}>
              <SafeAreaView edges={['top']}>
                <View style={headerStyles}>{headerContents}</View>
              </SafeAreaView>
            </GradientBackground>
            {children}
          </View>
        </GradientBackground>
      );
    }
  }

  // image background
  return (
    <ImageBackground
      source={currentBackground}
      style={[styles.content, { backgroundColor: COLORS.WHITE }, contentStyle]}
      blurRadius={
        radioTab?.blurredBackground === 'YES' ? DEFAULT_BACKGROUND_BLUR_RADIUS : 0
      }
    >
      <GradientBackground style={IS_WEB ? styles.webOffset : undefined}>
        <SafeAreaView edges={['top']}>
          <View style={headerStyles}>{headerContents}</View>
        </SafeAreaView>
      </GradientBackground>
      {children}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    backgroundColor: IS_WEB ? '#00000070' : undefined, // remove background color flare
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    height: mvs(69),
  },
  headerAndContentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  webOffset: {
    paddingTop: 25,
  },
});
