import {
  setTextDedications,
  setTextDedicationsError,
  setTextDedicationsLoading,
} from './slice';
import { getDedications } from '../../../api/radioWebsiteApi';
import { AppThunk } from '../../../app/store';
import { AppConfigModel } from '../../../models';

export const fetchTextDedications =
  (appConfig: AppConfigModel, bearerToken: string): AppThunk =>
  async dispatch => {
    if (!appConfig.urlSiteRadio) {
      return;
    }

    dispatch(setTextDedicationsLoading());

    try {
      const result = await getDedications(appConfig.urlSiteRadio, bearerToken);
      dispatch(setTextDedications(result));
    } catch (e) {
      dispatch(setTextDedicationsError());
    }
  };
