import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  source: ImageSourcePropType;
  sizeStyle?: StyleProp<ViewStyle>;
}

export const LogoImage = (props: Props) => {
  //todo must cache image
  return (
    <View style={[styles.container, props.sizeStyle]}>
      <Image source={props.source} style={styles.image} resizeMode="cover" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 12,
    overflow: 'hidden',
  },
  image: {
    height: 69,
    aspectRatio: 552 / 248, // Ratio connu de l'image reçu
  },
});
