import React from 'react';
import { Text, TextProps } from 'react-native';

import { useAppSelector } from '../app/hooks';
import { selectFontFamily } from '../features/appconfig/store/selectors';
import { TextAlign } from '../models';
import { getFontFamily } from '../sdk/helpers/fontMapper';

interface CustomTextProps extends TextProps {
  weight?: 'Regular' | 'Bold' | 'SemiBold';
  textAlign?: TextAlign;
}

const CustomText: React.FC<CustomTextProps> = ({
  children,
  style,
  weight = 'Regular',
  textAlign = 'left',
  ...props
}) => {
  const fontFamily = useAppSelector(selectFontFamily);
  const fontFamilyStyle = { fontFamily: getFontFamily(fontFamily, weight) };
  const textAlignStyle = { textAlign: textAlign };

  return (
    <Text style={[fontFamilyStyle, textAlignStyle, style]} {...props}>
      {children}
    </Text>
  );
};

export default CustomText;
