import {
  AppConfigModel,
  CurrentMusicModel,
  RawStreamModel,
  TitrationModel,
} from '../models';
import { makeApiCall } from '../sdk/api/api';

export const getTitration = async (
  appConfig: AppConfigModel,
  platform: 'android' | 'iphone',
  streamId: string,
): Promise<Readonly<TitrationModel>> => {
  const res = await makeApiCall<CurrentMusicModel>({
    url: `${process.env.REACT_APP_RADIOKING_API_URL}/getcurrenttitle?appid=${appConfig.idapplications}&app=${platform}&flux_id=${streamId}`,
  });
  return res.data.results as TitrationModel;
};

export const getTitrationWeb = async (
  appConfig: AppConfigModel,
  stream: RawStreamModel,
  bearerToken: string, //to keep the same signature as web
): Promise<TitrationModel> => {
  const config = {
    Authorization: 'Bearer ' + bearerToken,
  };
  const res = await makeApiCall<TitrationModel>({
    url: `${process.env.REACT_APP_MOBILE_API_URL}/${appConfig.idapplications}/titration?typeTitrage=${stream.typeTitrage}&urlFlux=${stream.urlFlux}&mountPoint=${stream.mountPoint}&identifiant=${stream.identifiant}&urlTitrage=${stream.urlTitrage}`,
    headers: config,
  });
  return res.data;
};

export const getCover = async (
  platform: 'android' | 'iphone',
  streamId: string,
  title: string,
  artist: string,
): Promise<string> => {
  try {
    const res = await makeApiCall<CurrentMusicModel>({
      url: `${process.env.REACT_APP_RADIOKING_API_URL}/getcover?appid=${
        process.env.REACT_APP_APP_ID
      }&app=${platform}&flux_id=${streamId}&title=${encodeURIComponent(
        title,
      )}&artist=${encodeURIComponent(artist)}`,
    });
    return res.data.results as string;
  } catch (err) {
    return '';
  }
};

export const getCoverWeb = async (
  appConfig: AppConfigModel,
  title: string,
  artist: string,
  bearerToken: string, //to keep the same signature as web
): Promise<TitrationModel> => {
  const config = {
    Authorization: 'Bearer ' + bearerToken,
  };
  const res = await makeApiCall<TitrationModel>({
    url: `${process.env.REACT_APP_MOBILE_API_URL}/${appConfig.idapplications}/cover?artist=${artist}&title=${title}`,
    headers: config,
  });
  return res.data;
};

export const getItunesLink = async (
  platform: 'android' | 'iphone',
  streamId: string,
  title: string,
  artist: string,
): Promise<string> => {
  try {
    const res = await makeApiCall<CurrentMusicModel>({
      url: `${process.env.REACT_APP_RADIOKING_API_URL}/getbuylinkitunes?appid=${process.env.REACT_APP_APP_ID}&app=${platform}&flux_id=${streamId}&title=${title}&artist=${artist}`,
    });
    return res.data.results as string;
  } catch (err) {
    return '';
  }
};
