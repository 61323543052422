import { AlertData, hideAlert as hide, showAlert as show } from './slice';
import { AppThunk } from '../../../app/store';

export const showAlert =
  (alert: AlertData): AppThunk =>
  dispatch => {
    dispatch(show(alert));
  };

export const hideAlert =
  (key: string): AppThunk =>
  dispatch => {
    dispatch(hide(key));
  };
