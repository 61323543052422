import { ViewStyle } from 'react-native';

import { LiveFeedPostSurveyResultsModel } from '../../../models';

export function computeSurveyResultBarStyles(resultPercent: number): ViewStyle | null {
  if (resultPercent) {
    return { width: `${resultPercent}%` };
  }

  return null;
}

export function parseSurveyResult(result?: string): number {
  let parsedResult = 0;

  // « result » looks like: "7% - Answer 1"
  if (result) {
    parsedResult = parseInt(result, 10) || 0;
  }

  return parsedResult;
}

export function parseSurveyResults(
  survey: LiveFeedPostSurveyResultsModel,
): [number, number, number, number, number] {
  const {
    resultat1: result1,
    resultat2: result2,
    resultat3: result3,
    resultat4: result4,
    resultat5: result5,
  } = survey;

  return [
    parseSurveyResult(result1),
    parseSurveyResult(result2),
    parseSurveyResult(result3),
    parseSurveyResult(result4),
    parseSurveyResult(result5),
  ];
}
