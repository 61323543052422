import SystemSetting from 'react-native-system-setting';

import { RootState } from '../../../app/reducer';
import { StreamModel } from '../../../models';

export const selectCurrentRadio = ({ currentRadioReducer }: RootState): StreamModel =>
  currentRadioReducer.currentRadio;

export const selectCurrentStreamToPlay = async ({
  currentRadioReducer,
}: RootState): Promise<string> => {
  if (
    currentRadioReducer.currentRadio.urlFluxHD &&
    currentRadioReducer.currentRadio.urlFluxHD !== ''
  ) {
    if (
      currentRadioReducer.currentRadio.urlFluxBD &&
      currentRadioReducer.currentRadio.urlFluxBD !== ''
    ) {
      // HD && BD
      if (currentRadioReducer.isHD) {
        return currentRadioReducer.currentRadio.urlFluxHD;
      } else {
        if (await SystemSetting.isWifiEnabled()) {
          return currentRadioReducer.currentRadio.urlFlux;
        } else {
          return currentRadioReducer.currentRadio.urlFluxBD;
        }
      }
    } else {
      // HD
      if (currentRadioReducer.isHD) {
        return currentRadioReducer.currentRadio.urlFluxHD;
      } else {
        return currentRadioReducer.currentRadio.urlFlux;
      }
    }
  } else if (
    currentRadioReducer.currentRadio.urlFluxBD &&
    currentRadioReducer.currentRadio.urlFluxBD !== ''
  ) {
    // BD
    if (currentRadioReducer.isHD) {
      return currentRadioReducer.currentRadio.urlFlux;
    } else {
      return currentRadioReducer.currentRadio.urlFluxBD;
    }
  }
  // No special stream

  return currentRadioReducer.currentRadio.urlFlux;
};

export const selectIsHD = ({ currentRadioReducer }: RootState): boolean =>
  currentRadioReducer.isHD;
