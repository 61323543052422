import { createSlice } from '@reduxjs/toolkit';

import { StreamModel } from '../../../models';

export type CurrentRadioSliceState = {
  currentRadio: StreamModel;
  isHD: boolean;
};

const initialState: CurrentRadioSliceState = {
  currentRadio: {
    id: 0,
    logoappfield: '',
    nomFlux: '',
    typeTitrage: '',
    urlFlux: '',
    urlFluxBD: '',
    urlFluxHD: '',
  },
  isHD: true,
};

const currentRadioSlice = createSlice({
  name: 'currentRadio',
  initialState,
  reducers: {
    updateCurrentRadio(state, action) {
      state.currentRadio = action.payload;
    },
    updateIsHD(state, action) {
      state.isHD = action.payload;
    },
  },
});
export const { updateCurrentRadio, updateIsHD } = currentRadioSlice.actions;
export const currentRadioReducer = currentRadioSlice.reducer;
