import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { getCoverWeb, getTitrationWeb } from '../api/radiokingApi.web';
import { useAppSelector } from '../app/hooks';
import { RUN_AS_TEST } from '../constants/Test';
import {
  selectAlwaysUseDefaultCoverOption,
  selectAppConfig,
  selectCover,
  selectRadioTab,
} from '../features/appconfig/store/selectors';
import { selectBearerToken } from '../features/bearertoken/store/selectors';
import { selectCurrentRadio } from '../features/currentradio/store/selectors';
import { updateCurrentRadio } from '../features/currentradio/store/slice';
import { updateCurrentTrack } from '../features/currenttrack/store/actions';
import { selectRawStreams } from '../features/rawStream/store/selectors';

export const useCurrentTrackUpdater = () => {
  const dispatch = useDispatch();
  const radioTab = useAppSelector(selectRadioTab);
  const currentRadio = useAppSelector(selectCurrentRadio);
  const defaultCover = useAppSelector(selectCover);
  const appConfig = useAppSelector(selectAppConfig);
  const bearerToken = useAppSelector(selectBearerToken);
  const rawStreams = useAppSelector(selectRawStreams);
  const intervalRef = useRef(null);
  const alwaysUseDefaultCover = useAppSelector(selectAlwaysUseDefaultCoverOption);

  const clearFetchTrackTimeout = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const fetchTrackInfo = useCallback(
    async (radio: string) => {
      if (currentRadio.id + '' !== radio) {
        clearFetchTrackTimeout();
      }
      let newTrack = {
        artist: '',
        title: '',
        url: '',
        image: null,
      };
      try {
        const currentRawStream = rawStreams.find(
          rawStream => rawStream.idflux + '' === radio,
        );
        if (currentRawStream) {
          newTrack = await getTitrationWeb(appConfig, currentRawStream, bearerToken);
        }
      } catch (e) {
        return;
      }

      if (!newTrack.image) {
        try {
          const coverFromApi = await getCoverWeb(
            appConfig,
            newTrack.title,
            newTrack.artist,
            bearerToken,
          );

          const newTrackWithCustomCover = {
            ...newTrack,
            image:
              coverFromApi && !RUN_AS_TEST && !alwaysUseDefaultCover
                ? coverFromApi
                : defaultCover,
            url: '',
          };
          dispatch(updateCurrentTrack(newTrackWithCustomCover));
        } catch (e) {
          if (newTrack.title) {
            const newTrackWithCustomCover = {
              ...newTrack,
              image:
                newTrack.image && !RUN_AS_TEST && !alwaysUseDefaultCover
                  ? newTrack.image
                  : defaultCover,
            };
            dispatch(updateCurrentTrack(newTrackWithCustomCover));
          }
        }
      } else {
        const newTrackWithCustomCover = {
          ...newTrack,
          image:
            newTrack.image && !RUN_AS_TEST && !alwaysUseDefaultCover
              ? newTrack.image
              : defaultCover,
        };
        dispatch(updateCurrentTrack(newTrackWithCustomCover));
      }
    },
    [
      alwaysUseDefaultCover,
      appConfig,
      bearerToken,
      clearFetchTrackTimeout,
      currentRadio,
      defaultCover,
      dispatch,
      rawStreams,
    ],
  );

  const runFetchTrackTimeout = useCallback(
    radio => {
      intervalRef.current = setInterval(() => fetchTrackInfo(radio), 15000);
    },
    [fetchTrackInfo],
  );

  useEffect(() => {
    if (currentRadio.id !== -1) {
      const isRadioInConfig = radioTab?.flux.findIndex(function (e) {
        return e.id === currentRadio.id;
      });

      if (isRadioInConfig !== -1) {
        fetchTrackInfo(currentRadio.id.toString());
        runFetchTrackTimeout(currentRadio.id);
      } else {
        dispatch(updateCurrentRadio(radioTab?.flux[0]));
      }
    }
    return () => {
      clearFetchTrackTimeout();
      const newTrack = {
        artist: null,
        title: null,
        url: null,
        image: null,
      };
      dispatch(updateCurrentTrack(newTrack));
    };
  }, [
    appConfig,
    clearFetchTrackTimeout,
    currentRadio,
    dispatch,
    fetchTrackInfo,
    rawStreams,
    radioTab,
    runFetchTrackTimeout,
  ]);

  return null;
};
