import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import pt from './pt.json';
import { SupportedLocalesCodes } from '../supportedLocales';

export type Translations = { [T in SupportedLocalesCodes]: typeof en };

export const translations: Translations = {
  en,
  fr,
  es,
  de,
  pt,
  it,
};
