import { TextStyle } from 'react-native';
import { mvs } from 'react-native-size-matters';

import { FONTS } from './Fonts';

type TextStyles = { [S in string]: TextStyle };

export const TEXT_STYLES: TextStyles = {
  regular11x17: {
    fontFamily: FONTS.Quicksand_400Regular,
    fontSize: mvs(11),
    lineHeight: mvs(17),
  },
  regular12x16: {
    fontFamily: FONTS.Quicksand_400Regular,
    fontSize: mvs(12),
    lineHeight: mvs(16),
  },
  regular12x18: {
    fontFamily: FONTS.Quicksand_400Regular,
    fontSize: mvs(12),
    lineHeight: mvs(18),
  },
  regular14x21: {
    fontSize: mvs(14),
    lineHeight: mvs(21),
  },
  bold12x18: {
    fontFamily: FONTS.Quicksand_700Bold,
    fontSize: mvs(12),
    lineHeight: mvs(18),
  },
  bold14x21: {
    fontFamily: FONTS.Quicksand_700Bold,
    fontSize: mvs(14),
    lineHeight: mvs(21),
  },
  bold18x27: {
    fontFamily: FONTS.Quicksand_700Bold,
    fontSize: mvs(18),
    lineHeight: mvs(27),
  },
};
