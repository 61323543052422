import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { RightDrawerNavigator } from './RightDrawerNavigator';

export type AppNavigatorParams = {
  Home: undefined;
  Modal: undefined;
  NotFound: undefined;
};

export type AppNavigatorScreenProps<Screen extends keyof AppNavigatorParams> =
  NativeStackScreenProps<AppNavigatorParams, Screen>;

// const Stack = createNativeStackNavigator<AppNavigatorParams>();

export const AppNavigator = () => {
  return <RightDrawerNavigator />;
  /*return (
    <Stack.Navigator initialRouteName="Home" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Home" component={RightDrawerNavigator} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );*/
};
