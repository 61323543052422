import { DrawerActions } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { mvs } from 'react-native-size-matters';

import { useAppSelector } from '../app/hooks';
import CustomText from '../components/CustomText';
import { COLORS } from '../constants';
import { selectDivRadius, selectHasMargin } from '../features/appconfig/store/selectors';
import i18n from '../localization/i18n';
import { AudioDedicationTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { AudioDedication } from '../sdk/components/AudioDedication';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import { isTablet, REDUCED_HORIZONTAL_MARGIN } from '../sdk/constants';
import useOrientation from '../sdk/hooks/useOrientation';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';

export default function AudioDedicationScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'AudioDedication'>) {
  const tabOptions = route.params.options as AudioDedicationTabModel;
  const windowWidth = useWindowDimensions().width;
  const orientation = useOrientation();
  const tabletContainerWidth = orientation === 'LANDSCAPE' ? windowWidth / 2.7 : mvs(350);
  const divRadius = useAppSelector(selectDivRadius);
  // applies custom margin config
  const hasMargin = useAppSelector(selectHasMargin);
  const bodyHorizontalMargin = hasMargin ? 10 : REDUCED_HORIZONTAL_MARGIN + 2;
  const textHorizonttalMargin = hasMargin ? mvs(22) : REDUCED_HORIZONTAL_MARGIN;

  // Dynamic font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));
  const tipTextFontSize = useAdjustedFontSize(mvs(14));

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const menuIcon = useMemo(() => {
    return (
      <CustomIcon
        name="icon_menu"
        color={tabOptions.headerTextColor}
        size={mvs(24)}
        onPress={toggleLeftDrawer}
      />
    );
  }, [toggleLeftDrawer, tabOptions]);

  const tabName = useMemo(() => {
    return (
      <View style={[styles.titleContainer]}>
        <CustomText
          numberOfLines={1}
          style={[
            styles.title,
            {
              color: tabOptions.headerTextColor,
              fontSize: titleFontSize,
              lineHeight: titleLineHeight,
            },
          ]} // Applied dynamic font size
          weight="Bold"
        >
          {tabOptions.titre}
        </CustomText>
      </View>
    );
  }, [tabOptions, titleFontSize, titleLineHeight]);

  return (
    <ScreenLayout
      contentStyle={styles.content}
      headerContents={
        <>
          {menuIcon}
          {tabName}
        </>
      }
    >
      <View
        style={[
          styles.body,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            width: isTablet ? tabletContainerWidth : 'auto',
            marginHorizontal: bodyHorizontalMargin,
          },
        ]}
      >
        <AudioDedication
          isRecording={false}
          isRecorded={false}
          isSent={false}
          toggleRecording={() => {}}
          toggleSent={() => {}}
          togglePlayPause={() => {}}
          isPlaying={false}
          countdown={0}
          duration={0}
          audioURI={null}
          isActive={true}
          width={isTablet ? tabletContainerWidth : windowWidth}
        />
      </View>

      <SafeAreaView edges={['bottom']}>
        <View
          style={[
            styles.tip,
            { borderRadius: mvs(divRadius), marginHorizontal: textHorizonttalMargin },
          ]}
        >
          <CustomText
            style={[styles.tipText, { fontSize: tipTextFontSize }]}
            numberOfLines={2}
            weight="Regular"
          >
            {i18n.t('audioDedication.tip')}
          </CustomText>
        </View>
      </SafeAreaView>
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  content: {
    justifyContent: 'space-between',
  },
  titleContainer: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-start',
    justifyContent: 'center',
  },
  title: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
  },
  body: {
    alignSelf: isTablet ? 'center' : 'auto',
  },
  tip: {
    backgroundColor: '#fff',
    paddingHorizontal: mvs(22),
    paddingVertical: mvs(8),
    marginBottom: mvs(27),
    marginTop: mvs(14),
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    alignSelf: 'center',
  },
  tipText: {
    color: '#474747',
  },
});
