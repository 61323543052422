import AsyncStorage from '@react-native-async-storage/async-storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import { alarmReducer, AlarmSliceState } from '../features/alarm/store/slice';
import { appConfigReducer, AppConfigSliceState } from '../features/appconfig/store/slice';
import {
  bearerTokenReducer,
  BearerTokenSliceState,
} from '../features/bearertoken/store/slice';
import {
  currentRadioReducer,
  CurrentRadioSliceState,
} from '../features/currentradio/store/slice';
import { CurrentTrack, currentTrackReducer } from '../features/currenttrack/store/slice';
import { liveFeedReducer, LiveFeedSliceState } from '../features/liveFeed/store/slice';
import { modalReducer, ModalSliceState } from '../features/modal/store/slice';
import { rawStreamReducer, RawStreamSliceState } from '../features/rawStream/store/slice';
import {
  textDedicationReducer,
  TextDedicationSliceState,
} from '../features/textDedication/store/slice';

const appConfigPersistConfig: PersistConfig<AppConfigSliceState> = {
  key: 'appConfigReducer',
  storage: AsyncStorage,
};

const textDedicationPersistConfig: PersistConfig<TextDedicationSliceState> = {
  key: 'textDedicationReducer',
  storage: AsyncStorage,
};

const liveFeedPersistConfig: PersistConfig<LiveFeedSliceState> = {
  key: 'liveFeedReducer',
  storage: AsyncStorage,
};

const modalPersistConfig: PersistConfig<ModalSliceState> = {
  key: 'modalReducer',
  storage: AsyncStorage,
  blacklist: ['alert'],
};

const alarmPersistConfig: PersistConfig<AlarmSliceState> = {
  key: 'alarmReducer',
  storage: AsyncStorage,
};

const currentTrackPersistConfig: PersistConfig<CurrentTrack> = {
  key: 'currentTrackReducer',
  storage: AsyncStorage,
};

const currentRadioPersistConfig: PersistConfig<CurrentRadioSliceState> = {
  key: 'currentRadioReducer',
  storage: AsyncStorage,
};

const bearerTokenPersistConfig: PersistConfig<BearerTokenSliceState> = {
  key: 'bearerTokenReducer',
  storage: AsyncStorage,
};

const rawStreamPersistConfig: PersistConfig<RawStreamSliceState> = {
  key: 'rawStreamReducer',
  storage: AsyncStorage,
};

export const rootReducer = combineReducers({
  appConfigReducer: persistReducer(appConfigPersistConfig, appConfigReducer),
  textDedicationReducer: persistReducer(
    textDedicationPersistConfig,
    textDedicationReducer,
  ),
  liveFeedReducer: persistReducer(liveFeedPersistConfig, liveFeedReducer),
  modalReducer: persistReducer(modalPersistConfig, modalReducer),
  alarmReducer: persistReducer(alarmPersistConfig, alarmReducer),
  currentTrackReducer: persistReducer(currentTrackPersistConfig, currentTrackReducer),
  currentRadioReducer: persistReducer(currentRadioPersistConfig, currentRadioReducer),
  bearerTokenReducer: persistReducer(bearerTokenPersistConfig, bearerTokenReducer),
  rawStreamReducer: persistReducer(rawStreamPersistConfig, rawStreamReducer),
});

const rootPersistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [
    'appConfigReducer',
    'textDedicationReducer',
    'liveFeedReducer',
    'modalReducer',
    'alarmReducer',
    'currentTrackReducer',
    'currentRadioReducer',
    'bearerTokenReducer',
    'rawStreamReducer',
  ],
};

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof persistedReducer>;
