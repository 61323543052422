import {
  GestureResponderEvent,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { RadioTitlePlayPauseIcon } from './RadioTitlePlayPauseIcon';

interface Props {
  style?: StyleProp<ViewStyle>;
  iconSize?: number;
  isStarted: boolean;
  onPress: (event: GestureResponderEvent) => void;
}

export const PlayPauseButton = ({ style, iconSize, isStarted, onPress }: Props) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
      style={style}
    >
      <RadioTitlePlayPauseIcon isShown={true} iconSize={iconSize} isPlaying={isStarted} />
    </TouchableOpacity>
  );
};
