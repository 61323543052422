import { DrawerActions } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mvs } from 'react-native-size-matters';
import { WebView } from 'react-native-webview';

import CustomText from '../components/CustomText';
import { COLORS } from '../constants';
import { useAdjustedFontSize } from '../hooks/useAdjustedFontSize';
import i18n from '../localization/i18n';
import { WebsiteTabModel } from '../models';
import { LeftDrawerNavigatorScreenProps } from '../navigation/LeftDrawerNavigator';
import { CustomIcon } from '../sdk/components/CustomIcon';
import { ScreenLayout } from '../sdk/components/ScreenLayout';
import { IS_WEB, isTablet } from '../sdk/constants';

export default function WebsiteScreen({
  route,
  navigation,
}: LeftDrawerNavigatorScreenProps<'Website'>) {
  const tabOptions = route.params.options as WebsiteTabModel;

  // Dynamic font sizes
  const titleFontSize = useAdjustedFontSize(mvs(25));
  const titleLineHeight = useAdjustedFontSize(mvs(30));
  const defaultWebviewMessageFontSize = useAdjustedFontSize(mvs(20));

  const toggleLeftDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [navigation]);

  const menuIcon = useMemo(() => {
    return (
      <CustomIcon
        name="icon_menu"
        color={tabOptions.headerTextColor}
        size={mvs(24)}
        onPress={toggleLeftDrawer}
      />
    );
  }, [toggleLeftDrawer, tabOptions]);

  const tabName = useMemo(() => {
    return (
      <View style={styles.titleContainer}>
        <CustomText
          numberOfLines={1}
          style={[
            styles.title,
            {
              color: tabOptions.headerTextColor,
              fontSize: titleFontSize,
              lineHeight: titleLineHeight,
            },
          ]}
          weight="Bold"
        >
          {tabOptions.titre}
        </CustomText>
      </View>
    );
  }, [tabOptions, titleFontSize, titleLineHeight]);

  return (
    <ScreenLayout
      headerContents={
        <>
          {menuIcon}
          {tabName}
        </>
      }
    >
      {IS_WEB && (
        <View style={styles.defaultWebview}>
          <Text
            style={[
              styles.defaultWebviewMessage,
              { fontSize: defaultWebviewMessageFontSize },
            ]}
          >
            {i18n.t('webview.previewNotAvailable')}
          </Text>
        </View>
      )}
      {!IS_WEB && (
        <WebView
          style={styles.webview}
          source={{ uri: tabOptions.urlSite }}
          allowsFullscreenVideo={true}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          originWhitelist={['http://', 'https://', 'about:']}
        />
      )}
    </ScreenLayout>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    alignItems: isTablet ? 'center' : 'flex-start',
  },
  title: {
    flexShrink: 1,
    marginHorizontal: 12,
    color: COLORS.WHITE,
  },
  webview: {
    flexShrink: 0,
  },
  defaultWebview: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: COLORS.WHITE,
  },
  defaultWebviewMessage: {
    textAlign: 'center',
    paddingHorizontal: 30,
  },
});
