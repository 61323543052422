import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { mvs } from 'react-native-size-matters';

import CustomText from '../../components/CustomText';
import { TEXT_STYLES } from '../../constants';
import { useButtonColors } from '../../hooks/useButtonColors';

interface Props {
  text: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export const Button = (props: Props) => {
  const { gradientBackgroundColours, iconOrTextColor } = useButtonColors();

  return (
    <TouchableOpacity
      onPress={props.onPress}
      hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
    >
      <LinearGradient
        // gradient initilalized with a single color
        // prepared for future addition of a secondary color.
        colors={gradientBackgroundColours}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={[styles.container, props.style]}
      >
        <CustomText
          style={[styles.text, props.textStyle, { color: iconOrTextColor }]}
          weight="Regular"
        >
          {props.text}
        </CustomText>
      </LinearGradient>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: mvs(40),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  text: {
    ...TEXT_STYLES.regular14x21,
  },
});
