import React from 'react';
import { StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { GradientDirection } from '../../models';

interface Props {
  colorTop: string;
  colorBottom: string;
  children?: React.ReactNode;
  direction?: GradientDirection;
}

const directionCoordinates = {
  bottom: { start: { x: 0.5, y: 0 }, end: { x: 0.5, y: 1 } },
  'bottom-right': { start: { x: 0, y: 0 }, end: { x: 1, y: 1 } },
  right: { start: { x: 0, y: 0.5 }, end: { x: 1, y: 0.5 } },
  'bottom-left': { start: { x: 1, y: 0 }, end: { x: 0, y: 1 } },
};

export const BicolorBackground = ({
  colorTop,
  colorBottom,
  children,
  direction = 'bottom-right',
}: Props) => {
  const { start, end } = directionCoordinates[direction];

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={[colorTop, colorTop, colorBottom, colorBottom]}
        locations={[0, 0.5, 0.5, 1]}
        start={start}
        end={end}
        style={styles.gradient}
      />
      <View style={styles.content}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  content: {
    flex: 1,
  },
});
