import { createSlice } from '@reduxjs/toolkit';

export interface AlarmData {
  key: number;
  enable: boolean;
  name: string;
  time: Date;
  days: Array<boolean>;
}

export type AlarmSliceState = {
  alarms: Array<AlarmData>;
};

const initialState: AlarmSliceState = {
  alarms: [],
};

const alarmSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    addAlarm(state, action) {
      state.alarms.push(action.payload);
    },
    removeAlarm(state, action) {
      state.alarms = state.alarms.filter(data => data.key !== action.payload.key);
    },
    changeAlarmEnability(state, action) {
      for (let i = 0; i < state.alarms.length; i++) {
        if (state.alarms[i].key === action.payload.key) {
          state.alarms[i].enable = !state.alarms[i].enable;
        }
      }
    },

    updateAlarm(state, action) {
      for (let i = 0; i < state.alarms.length; i++) {
        if (state.alarms[i].key === action.payload.key) {
          state.alarms[i] = action.payload;
        }
      }
    },
  },
});
export const { addAlarm, changeAlarmEnability, removeAlarm, updateAlarm } =
  alarmSlice.actions;
export const alarmReducer = alarmSlice.reducer;
