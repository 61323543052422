import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { Image, StyleSheet } from 'react-native';

import { useAppSelector } from '../../app/hooks';
import { selectIconStyle } from '../../features/appconfig/store/selectors';
import {
  LEFT_DRAWER_DEFAULT_ICON_WIDTH,
  LEFT_DRAWER_FOCUSED_ICON_WIDTH,
} from '../constants';

export const CustomDrawerIcon = ({
  focused,
  color,
  icon,
  accessibilityLabel,
  testID,
  faIcon,
}: Parameters<NonNullable<DrawerNavigationOptions['drawerIcon']>>[0] & {
  icon: string;
  accessibilityLabel: string;
  testID: string;
  faIcon: string;
}) => {
  const iconStyle = useAppSelector(selectIconStyle);
  const faIconWithStyle = faIcon ? getFaIconWithStyle(faIcon, iconStyle) : null;

  // FIXME Remove this check, only here to avoid an annoying warning on Android because icons are undefined
  if (!icon) {
    return null;
  }

  const style = { tintColor: color };
  if (faIcon) {
    return (
      <FontAwesomeIcon
        size={20}
        secondaryColor={color}
        color={color}
        icon={faIconWithStyle as IconProp}
        style={
          !focused
            ? [styles.drawerIcon, style]
            : [styles.drawerIcon, styles.drawerIconFocused, style]
        }
      />
    );
  } else {
    return (
      <Image
        source={{ uri: icon }}
        resizeMode="contain"
        style={
          !focused
            ? [styles.drawerIcon, style]
            : [styles.drawerIcon, styles.drawerIconFocused, style]
        }
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        testID={testID}
      />
    );
  }
};

const styles = StyleSheet.create({
  drawerIcon: {
    marginLeft: -8, // Padding of 8 is hardcoded in react-navigation
    minHeight: LEFT_DRAWER_DEFAULT_ICON_WIDTH,
    minWidth: LEFT_DRAWER_DEFAULT_ICON_WIDTH,
    alignItems: 'center',
  },
  drawerIconFocused: {
    minHeight: LEFT_DRAWER_FOCUSED_ICON_WIDTH,
    minWidth: LEFT_DRAWER_FOCUSED_ICON_WIDTH,
  },
});

const getFaIconWithStyle = (faIcon: string, iconStyle: string): [string, string] => {
  const parts = faIcon.split(' ');
  const prefix = parts[0].replace('fa-', '');
  const transformedFaIcon =
    parts.length > 1
      ? parts[1].replace('fa-', '')
      : (parts[0].replace('fa-', '') as IconName);

  const faIconPrefix =
    prefix === 'brands' ? 'fab' : iconStyle === 'regular' ? 'far' : 'fas';

  return [faIconPrefix, transformedFaIcon];
};
