import getVideoId from 'get-video-id';

/**
 * Return a YouTube thumbnail url from its video url
 *
 * See https://www.youtube.com/oembed?url=[YOUTUBE_URL]&format=json
 */
export function thumbnailUrlFromYoutubeUrl(youtubeUrl: string): string | null {
  const youtubeVideoId = getVideoId(youtubeUrl).id;

  return youtubeVideoId ? thumbnailUrlFromYoutubeVideoId(youtubeVideoId) : null;
}

/**
 * Return a YouTube thumbnail url from its video id
 *
 * See https://www.youtube.com/oembed?url=[YOUTUBE_URL]&format=json
 */
export function thumbnailUrlFromYoutubeVideoId(youtubeVideoId: string): string {
  return `https://i.ytimg.com/vi/${youtubeVideoId}/hqdefault.jpg`;
}
