import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataState } from '../../../constants';
import { RawStreamModel } from '../../../models';

export type RawStreamSliceState = {
  rawStreams: {
    data: RawStreamModel[];
    state: DataState;
  };
};

const initialState: RawStreamSliceState = {
  rawStreams: { data: [], state: DataState.NOT_LOADED },
};

const rawStreamSlice = createSlice({
  name: 'rawStream',
  initialState,
  reducers: {
    setRawStreamsLoading: state => {
      state.rawStreams = { ...state.rawStreams, state: DataState.LOADING };
    },
    setRawStreamsError: state => {
      state.rawStreams = { ...state.rawStreams, state: DataState.ERROR };
    },
    setRawStreams: (state, { payload }: PayloadAction<RawStreamModel[]>) => {
      state.rawStreams = { data: payload, state: DataState.LOADED };
    },
  },
});
export const { setRawStreamsLoading, setRawStreamsError, setRawStreams } =
  rawStreamSlice.actions;
export const rawStreamReducer = rawStreamSlice.reducer;
