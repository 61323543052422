import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataState } from '../../../constants';
import { DedicationModel } from '../../../models';

export type TextDedicationSliceState = {
  dedications: {
    data: DedicationModel[];
    state: DataState;
  };
};

const initialState: TextDedicationSliceState = {
  dedications: { data: [], state: DataState.NOT_LOADED },
};

const textDedicationSlice = createSlice({
  name: 'textDedication',
  initialState,
  reducers: {
    setTextDedicationsLoading: state => {
      state.dedications = { ...state.dedications, state: DataState.LOADING };
    },
    setTextDedicationsError: state => {
      state.dedications = { ...state.dedications, state: DataState.ERROR };
    },
    setTextDedications: (state, { payload }: PayloadAction<DedicationModel[]>) => {
      state.dedications = { data: payload, state: DataState.LOADED };
    },
  },
});
export const { setTextDedicationsLoading, setTextDedicationsError, setTextDedications } =
  textDedicationSlice.actions;
export const textDedicationReducer = textDedicationSlice.reducer;
