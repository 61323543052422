import { createSlice } from '@reduxjs/toolkit';

import { TitrationModel } from '../../../models';

export interface CurrentTrack {
  currentTrack: TitrationModel;
}

const initialState: CurrentTrack = {
  currentTrack: {
    artist: '',
    title: '',
    album: '',
    url: '',
    image: '',
    title_str: '',
  },
};

const currentTrackSlice = createSlice({
  name: 'currentTrack',
  initialState,
  reducers: {
    updateCurrentTrack(state, action) {
      state.currentTrack.title = action.payload.title;
      state.currentTrack.artist = action.payload.artist;
      state.currentTrack.album = action.payload.album;
      state.currentTrack.url = action.payload.url;
      state.currentTrack.image = action.payload.image;
      state.currentTrack.title_str = action.payload.title_str;
    },
  },
});
export const { updateCurrentTrack } = currentTrackSlice.actions;
export const currentTrackReducer = currentTrackSlice.reducer;
