import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataState } from '../../../constants';
import { AppConfigModel } from '../../../models';

export type AppConfigSliceState = {
  appConfig: {
    data: AppConfigModel | null;
    state: DataState;
  };
  lastUpdate: string | null;
  status:
    | 'deleted'
    | 'brouillon'
    | 'terminee'
    | 'publication'
    | 'active'
    | 'publication_fail'
    | 'desactivee';
};

const initialState: AppConfigSliceState = {
  appConfig: { data: null, state: DataState.NOT_LOADED }, // TODO correct initialisation
  lastUpdate: null,
  status: 'desactivee',
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setAppConfigLoading: state => {
      state.appConfig = { ...state.appConfig, state: DataState.LOADING };
    },
    setAppConfigError: state => {
      state.appConfig = { ...state.appConfig, state: DataState.ERROR };
    },
    setAppConfig: (state, { payload }: PayloadAction<AppConfigModel>) => {
      state.appConfig = { ...state.appConfig, data: payload, state: DataState.LOADED };
    },
    setLastUpdate: (
      state,
      { payload }: PayloadAction<AppConfigSliceState['lastUpdate']>,
    ) => {
      state.lastUpdate = payload;
    },
    setStatus: (state, { payload }: PayloadAction<AppConfigSliceState['status']>) => {
      state.status = payload;
    },
  },
});
export const {
  setAppConfigLoading,
  setAppConfigError,
  setAppConfig,
  setLastUpdate,
  setStatus,
} = appConfigSlice.actions;
export const appConfigReducer = appConfigSlice.reducer;
