import { StyleProp, ViewStyle } from 'react-native';

import { CustomIcon } from './CustomIcon';
import { PlayPauseIconContainer } from './PlayPauseIconContainer';
import { useButtonColors } from '../../hooks/useButtonColors';

interface Props {
  isPlaying: boolean;
  style?: StyleProp<ViewStyle>;
  iconSize?: number;
}

export const PlayPauseIcon = ({ style, isPlaying, iconSize }: Props) => {
  const { iconOrTextColor } = useButtonColors();

  return (
    <PlayPauseIconContainer style={style}>
      <CustomIcon
        name={isPlaying ? 'icon_pause' : 'icon_play_with_offset'}
        color={iconOrTextColor}
        size={iconSize || 40}
      />
    </PlayPauseIconContainer>
  );
};
