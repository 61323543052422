import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

export default function useOrientation(): string | null {
  const [orientation, setOrientation] = useState<string | null>(null);

  function determineAndSetOrientation(): void {
    const width: number = Dimensions.get('window').width;
    const height: number = Dimensions.get('window').height;

    if (width < height) {
      setOrientation('PORTRAIT');
    } else {
      setOrientation('LANDSCAPE');
    }
  }

  useEffect(() => {
    determineAndSetOrientation();

    const subscription = Dimensions.addEventListener(
      'change',
      determineAndSetOrientation,
    );
    return () => subscription?.remove();
  }, []);

  return orientation;
}
